<template>
  <div>
    <welcome-conference-banner v-if='showConferenceBanner' 
      class='mb-8'/>
    <div class='px-4 lg:px-0'>
      <my-page-profile v-if='includeProfile'
        :profile-helper-message='profileHelperMessage'
        class='mt-12 mb-24'/>
      <my-page-barcode v-if='includeBarcode' 
        class='mt-12 mb-24' />
      <my-page-certificates v-if='includeCertificates' 
        class='mt-12 mb-24' />
      <my-page-downloads v-if='includeDownloads' 
        :show-download-certificates='includeDownloadCertificates && myPageDataShowDownloadCertificates' 
        :show-download-receipts='includeDownloadReceipts' 
        class='mt-12 mb-24' />
      <my-page-registration-bank-info v-if='includeRegistrationBankInfo'
        class='mt-12 mb-24' />
      <my-page-watched-time v-if='includeWatchedTime' 
        class='mt-12 mb-24' />
      <component v-if='includeScore'
        :is='scoreComponent'
        class='mt-12 mb-24' />
      <my-page-stamp v-if='includeStamp' 
        class='mt-12 mb-24' />
      <my-page-quiz v-if='includeQuiz' 
        class='mt-12 mb-24' />
      <my-page-my-schedules v-if='includeMySchedules && myPageMySchedules.length'
        class='mt-12 mb-24' />
      <my-page-liked-sessions v-if='includeLikedSessions' 
        class='mt-12 mb-24' />
      <button v-if='showLogoutButton'
        class='logout-button w-full lg:w-32 uppercase border px-8 py-2 text-center rounded-md hover:shadow-md cursor-pointer'
        @click='logoutApp'>
        Logout
      </button>
    </div>
    <welcome-sponsor-carousel v-if='showSponsorCarousel' 
      class='mt-8' />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'MyPage',
  components: {
    'my-page-profile': () => import('@/components/my-page/MyPageProfile.vue'),
    'my-page-barcode': () => import('@/components/my-page/MyPageBarcode.vue'),
    'my-page-certificates': () => import('@/components/my-page/MyPageCertificates.vue'),
    'my-page-downloads': () => import('@/components/my-page/MyPageDownloads.vue'),
    'my-page-score': () => import('@/components/my-page/MyPageScore.vue'),
    'my-page-score-cmccme': () => import('@/components/my-page/MyPageScoreCmccme.vue'),
    'my-page-watched-time': () => import('@/components/my-page/MyPageWatchedTime.vue'),
    'my-page-stamp': () => import('@/components/my-page/MyPageStamp.vue'),
    'my-page-quiz': () => import('@/components/my-page/MyPageQuiz.vue'),
    'my-page-liked-sessions': () => import('@/components/my-page/MyPageLikedSessions.vue'),
    'my-page-registration-bank-info': () => import('@/components/my-page/MyPageRegistrationBankInfo.vue'),
    'my-page-my-schedules': () => import('@/components/my-page/MyPageMySchedules.vue'),
    'welcome-conference-banner': () => import('@/components/welcome/WelcomeConferenceBanner.vue'),
    'welcome-sponsor-carousel': () => import('@/components/welcome/WelcomeSponsorsCarousel.vue'),
  },
  props: {
    showConferenceBanner: {
      type: Boolean,
      default: false,
    },
    showSponsorCarousel: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('events', [
      'showingEventMyPageConfigurations',
      'isCmccmeEvent',
    ]),
    ...mapGetters('myPages', [
      'myPageDataShowDownloadCertificates',
      'myPageMySchedules',
    ]),
    includeProfile () {
      return (this.showingEventMyPageConfigurations) ? this.showingEventMyPageConfigurations.includeProfile : false
    },
    includeBarcode () {
      return (this.showingEventMyPageConfigurations) ? this.showingEventMyPageConfigurations.includeBarcode : false
    },
    profileHelperMessage () {
      return (this.showingEventMyPageConfigurations) ? this.showingEventMyPageConfigurations.profileHelperMessage : ''
    },
    includeCertificates () {
      return (this.showingEventMyPageConfigurations) ? this.showingEventMyPageConfigurations.includeCertificates : false
    },
    includeDownloads () {
      return (this.showingEventMyPageConfigurations) ? this.showingEventMyPageConfigurations.includeDownloads : false
    },
    includeDownloadCertificates () {
      return (this.showingEventMyPageConfigurations) ? this.showingEventMyPageConfigurations.includeDownloadCertificates : false
    },
    includeDownloadReceipts () {
      return (this.showingEventMyPageConfigurations) ? this.showingEventMyPageConfigurations.includeDownloadReceipts : false
    },
    includeWatchedTime () {
      return (this.showingEventMyPageConfigurations) ? this.showingEventMyPageConfigurations.includeWatchedTime : false
    },
    includeScore () {
      return (this.showingEventMyPageConfigurations) ? this.showingEventMyPageConfigurations.includeScore : false
    },
    includeMySchedules () {
      return (this.showingEventMyPageConfigurations) ? this.showingEventMyPageConfigurations.includeMySchedules : false
    },
    includeStamp () {
      return (this.showingEventMyPageConfigurations) ? this.showingEventMyPageConfigurations.includeStamp : false
    },
    includeQuiz () {
      return (this.showingEventMyPageConfigurations) ? this.showingEventMyPageConfigurations.includeQuiz : false
    },
    includeLikedSessions () {
      return (this.showingEventMyPageConfigurations) ? this.showingEventMyPageConfigurations.includeLikedSessions : false
    },
    includeRegistrationBankInfo () {
      return (this.showingEventMyPageConfigurations) ? this.showingEventMyPageConfigurations.includeRegistrationBankInfo : false
    },
    scoreComponent () {
      return (this.isCmccmeEvent) ? 'my-page-score-cmccme' : 'my-page-score'
    },
    showLogoutButton () {
      return this.showingEventMyPageConfigurations && 
             this.showingEventMyPageConfigurations.includeLogoutButton &&
             !this.includeProfile
    },
  },
  methods: {
    ...mapActions('myPages', [
      'getMyPageData',
    ]),
    ...mapActions('users', [
      'logout',
    ]),
    logoutApp () {
      this.logout().then(() => {
        this.$router.push({name: 'Login'})
      })
    }
  },
  mounted () {
    this.getMyPageData()
  }
}
</script>

<style lang='scss' scoped>
  .logout-button {
    transition: all 0.3s ease-in-out;
  }
</style>
